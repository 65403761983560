import { Box, Button, Typography, Divider, Grid, IconButton } from "@mui/material";
import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import FormItemInput from "../FormItems/FormItemInput"; // Adjust the import path as necessary
import { Form, Select } from 'antd';
import { countries } from '../../Utils/countries';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

type Hardware = {
  _id?: string;  // Make _id optional
  deviceId: string;
  deviceModel: string;
  source: string;
  warranty: string | null;
  purchaseDate: string | null;
  invoice: string | File | null;  // Change this line
  deviceImage: string | File | null;  // Change this line
  serialNumber: string;
};

type createOrgProp = {
  alertMessage?: {
    type: string;
    message: string;
  };
  allotedHardwares: Array<{
    _id: string;
    serialNumber: string;
    deviceId:string;
    deviceModel :string;
    source :string;
    warranty :string;
    purchaseDate:string;
    invoice:string;
    deviceImage:string;
  }>;
  organizationDetails: {
    name: string;
    address: string;
    city: string;
    country: string;
    zip: string;
    phone: string;
    adminName: string;
    adminEmail: string;
    alternatePhone: string;
    trainingPackageId: string;
    trainingId: string;
    userLicenseCount: number;
  };
};

type CreateHardwareDetailFormProps = createOrgProp & {
  updateParentComponent: (updatedHardwareList: any[]) => void;
};

const CreateHardwareDetailForm: React.FC<CreateHardwareDetailFormProps> = ({ alertMessage, allotedHardwares, organizationDetails, updateParentComponent }) => {
  console.log('Received allotedHardwares:', allotedHardwares);

  const [hardwareList, setHardwareList] = useState<Hardware[]>(
    allotedHardwares.map((hardware) => ({
      _id: hardware._id || "",
      deviceId: hardware.deviceId || "",
      deviceModel: hardware.deviceModel || "",
      source: hardware.source || "",
      warranty: hardware.warranty || "",
      purchaseDate: hardware.purchaseDate || "",
      invoice: hardware.invoice || null,
      deviceImage: hardware.deviceImage || null,
      serialNumber: hardware.serialNumber || "",
    }))
  );

  console.log('Initialized hardwareList:', hardwareList);

  const lastHardwareRef = useRef<HTMLDivElement>(null);

  const handleInputChange = useCallback((index: number, field: string, value: string) => {
    setHardwareList(prevList => {
      const updatedList = [...prevList];
      updatedList[index] = { ...updatedList[index], [field]: value };
      return updatedList;
    });
    updateParentComponent(hardwareList);
  }, [hardwareList, updateParentComponent]);

  const handleFileChange = useCallback((index: number, field: string, file: File | null) => {
    if (file) {
      setHardwareList(prevList => {
        const updatedList = [...prevList];
        updatedList[index] = { 
          ...updatedList[index], 
          [field]: file  // Store the actual file object instead of the URL
        };
        console.log(`Updated ${field}:`, file);
        return updatedList;
      });
      updateParentComponent(hardwareList);
    }
  }, [hardwareList, updateParentComponent]);
  

  // Use useMemo to avoid unnecessary recalculations
  const memoizedHardwareList = useMemo(() => hardwareList, [hardwareList]);

  // Debounce the updateParentComponent call
  useEffect(() => {
    const timer = setTimeout(() => {
      updateParentComponent(memoizedHardwareList);
    }, 300);
    return () => clearTimeout(timer);
  }, [memoizedHardwareList, updateParentComponent]);

  const addHardware = () => {
    const newHardware: Hardware = {
      deviceId: "",
      deviceModel: "",
      source: "",
      warranty: null,
      purchaseDate: null,
      invoice: "",
      deviceImage: "",
      serialNumber: "",
    };
    const updatedHardwareList = [...hardwareList, newHardware];
    setHardwareList(updatedHardwareList);
    updateParentComponent(updatedHardwareList);

    // Scroll to the new hardware detail
    setTimeout(() => {
      if (lastHardwareRef.current) {
        lastHardwareRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  };

  const deleteHardware = (index: number) => {
    const updatedHardwareList = hardwareList.filter((_, i) => i !== index);
    setHardwareList(updatedHardwareList);
    updateParentComponent(updatedHardwareList);
  };

  const handleSubmit = () => {
    // Prepare data for submission
    const submitData = {
      org: {
        name: organizationDetails.name,
        image: null,
        address: organizationDetails.address,
        city: organizationDetails.city,
        country: organizationDetails.country,
        zip: organizationDetails.zip,
        phone: organizationDetails.phone,
        adminName: organizationDetails.adminName,
        adminEmail: organizationDetails.adminEmail,
        alternatePhone: organizationDetails.alternatePhone,
        trainingPackage: {
          _id: organizationDetails.trainingPackageId,
        },
        training: {
          _id: organizationDetails.trainingId,
        },
        userLicenseCount: organizationDetails.userLicenseCount,
        // All the hardware details are now only inside this array
        allotedHardwares: hardwareList.map(hardware => ({
          _id: hardware._id,
          serialNumber: hardware.serialNumber,
          deviceId: hardware.deviceId,
          deviceModel: hardware.deviceModel,
          source: hardware.source,
          warranty: hardware.warranty,
          purchaseDate: hardware.purchaseDate,
          invoice: hardware.invoice,
          deviceImage: hardware.deviceImage,
        })),
      },
    };

    // Log for debugging
    console.log("Submitting data:", submitData);

    // Call your mutation or API function here with the structured data
  };

  const deviceModels = [
    { value: 'Meta Quest 2', label: 'Meta Quest 2' },
    { value: 'Meta Quest 3', label: 'Meta Quest 3' },
    { value: 'Meta Quest 3S', label: 'Meta Quest 3S' },
    { value: 'Apple Vision Pro', label: 'Apple Vision Pro' },
  ];

  const fileInputRef = useRef<HTMLInputElement>(null);

  const triggerFileInput = (inputId: string) => {
    const fileInput = document.getElementById(inputId) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const renderFilePreview = (file: File | string | null) => {
    if (!file) {
      return <AddIcon fontSize="large" />;
    }

    if (typeof file === 'string') {
      // It's a URL, render image
      return <img src={file} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />;
    }

    if (file instanceof File) {
      if (file.type.startsWith('image/')) {
        // It's an image file, render preview
        return <img src={URL.createObjectURL(file)} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />;
      } else {
        // It's not an image, show file icon and name
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <InsertDriveFileIcon fontSize="large" />
            <Typography variant="caption" sx={{ textAlign: 'center', wordBreak: 'break-word' }}>
              {file.name}
            </Typography>
          </Box>
        );
      }
    }

    return null;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1000,
          padding: 2,
          borderBottom: "1px solid lightgray",
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item xs={6} sm={4}>
            <FormItemInput
              label="User License Count"
              name="userLicenseCount"
              rules={[{ required: true, message: 'User license count is required' }]}
              type="number"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Button variant="outlined" onClick={addHardware}>
              Add Hardware
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} paddingX={3} sx={{ marginTop: 2 }}>
        {hardwareList.map((hardware, index) => {
          console.log(`Rendering hardware ${index}:`, hardware);
          return (
            <Box key={index} ref={index === hardwareList.length - 1 ? lastHardwareRef : null} sx={{ width: "100%" }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={11}>
                  <Typography variant="h6">Hardware Details</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteHardware(index)}
                    disabled={index < allotedHardwares.length}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid container columnSpacing={3} rowSpacing={1} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <FormItemInput
                    label="Serial Number"
                    name={`serialNumber-${index}`}
                    type="text"
                    value={hardware.serialNumber}
                    onChange={(e) => handleInputChange(index, "serialNumber", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormItemInput
                    label="Device ID"
                    name={`deviceId-${index}`}
                    type="text"
                    value={hardware.deviceId}
                    onChange={(e) => handleInputChange(index, "deviceId", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Device Model</Typography>
                  <Select
                    showSearch
                    placeholder="Select a device model"
                    optionFilterProp="children"
                    onChange={(value) => handleInputChange(index, "deviceModel", value)}
                    value={hardware.deviceModel || undefined}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={deviceModels}
                    style={{ width: '100%' ,marginBottom:'5%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Country of purchase</Typography>
                  <Select
                    showSearch
                    placeholder="Select a country"
                    optionFilterProp="children"
                    onChange={(value) => handleInputChange(index, "source", value)}
                    value={hardware.source || undefined}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={countries}
                    style={{ width: '100%',marginBottom:'5%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormItemInput
                    label="Warranty Valid Till"
                    name={`warranty-${index}`}
                    type="date"
                    value={hardware.warranty || ""}
                    onChange={(e) => handleInputChange(index, "warranty", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormItemInput
                    label="Purchase Date"
                    name={`purchaseDate-${index}`}
                    type="date"
                    value={hardware.purchaseDate || ""}
                    onChange={(e) => handleInputChange(index, "purchaseDate", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">Invoice</Typography>
                  <Box
                    sx={{
                      width: 100,
                      height: 100,
                      border: '1px dashed grey',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      overflow: 'hidden',
                    }}
                    onClick={() => triggerFileInput(`invoice-upload-${index}`)}
                  >
                    {renderFilePreview(hardware.invoice)}
                  </Box>
                  <input
                    id={`invoice-upload-${index}`}
                    type="file"
                    hidden
                    accept="image/*,.pdf"
                    onChange={(e) => handleFileChange(index, "invoice", e.target.files?.[0] || null)}
                    style={{ display: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">Device Image</Typography>
                  <Box
                    sx={{
                      width: 100,
                      height: 100,
                      border: '1px dashed grey',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      overflow: 'hidden',
                    }}
                    onClick={() => triggerFileInput(`device-image-upload-${index}`)}
                  >
                    {renderFilePreview(hardware.deviceImage)}
                  </Box>
                  <input
                    id={`device-image-upload-${index}`}
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleFileChange(index, "deviceImage", e.target.files?.[0] || null)}
                    style={{ display: 'none' }}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ marginY: 2 }} />
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};

export default React.memo(CreateHardwareDetailForm);
