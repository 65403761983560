import { Typography } from "@mui/material";
import { Input } from "antd";

type props = {
  heading: string;
  setSearch?: (val: any) => void;  // Make setSearch optional
  setPage: (val: any) => void;
};

const TableHeading = ({ heading, setSearch, setPage }: props) => {
  return (
    <div>
      <Typography align="left">{heading}</Typography>
      {setSearch && (  // Conditionally render the search input
        <Input.Search
          onChange={(e) => {
            setSearch(e.target.value);
            setPage(1);
          }}
          placeholder="Search here"
        />
      )}
    </div>
  );
};

export default TableHeading;