import { useLazyQuery } from "@apollo/client";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import { Form, Image, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Message from "../../Components/Message";
import { SIGIN_POST } from "../../Graphql/Mutations";
import { CommonPaths } from "../../Routes/Paths";
import { login } from "../../Store/auth/authSlice";
import { Error } from "../../Types/MessageType";
import { SiginType } from "../../Types/siginType";
import Logo from '../../assets/IQuest.png';

const Sigin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState<Error>();
  const [sigIn] = useLazyQuery(SIGIN_POST);

  const state = location.state as { from: Location; };
  const from = state ? state.from.pathname : CommonPaths.HOME_PATH;

  const { loggedIn } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (loggedIn) {
      navigate(from, { replace: true });
    }
  }, [from, navigate, loggedIn]);

  const loginHandler = (values: SiginType) => {
    sigIn({
      variables: {
        email: values.email,
        password: values.password
      },
      onCompleted(data) {
        dispatch(login(data.signIn));
      },
      onError(error) {
        setAlert({
          type: 'error',
          message: error.message
        });
      }
    });
  };

  return (
    <>
      <Grid container height={"100%"} sx={{ minHeight: '700px' }} width={'100vw'} justifyContent="center" alignItems="center" px={{ sm: 2, md: 20 }}>
        <Grid item md={6}>
          <Image src={Logo} alt="logo" preview={false} style={{ width: '470px', height: 'auto',marginLeft:'-10%' }} />
        </Grid>
        <Grid item md={6}>
          <Paper elevation={2} sx={{ backgroundColor: theme.palette.background.paper, pt: 8, pb: 8, px: 4, marginTop: '35px' }}>
            <Typography color={'primary'} textAlign={'center'} variant={'h5'} sx={{ mb: 3 }}>
              Welcome to ImmersiveQuest
            </Typography>
            <Form
              form={form}
              layout={'vertical'}
              style={{ padding: '0 10%' }}
              onFinish={loginHandler}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your username' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item style={{ marginTop: '45px', marginBottom: '-25px' }}>
                <Button type={'submit'} variant={'contained'} style={{ margin: '0 40%', color: 'white', height: '45px' }} >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <Box height={theme.status.errorMessageSpace}>
              {!!alert?.message ? <Message type={alert?.type} message={alert.message} /> : null}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Sigin;