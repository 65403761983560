import { useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux"; // Or your state management library
import { Link } from "react-router-dom";
import Card from "../../Components/Cards/Card";
import DataTable from "../../Components/Datatable";
import Stats from "../../Components/Stats";
import TableHeading from "../../Components/TableHeading";
import globals from "../../Constants/Globals";
import { GET_TRAINING_MODULE, GET_USER_BY_ID, GET_ORG_BY_ID } from "../../Graphql/Queries";
import { SuperAdminPaths } from "../../Routes/Paths";
import { pages } from "../../Types/DatatableType";

const TrainingPackage = () => {
  const { CREATE_TRAINING_PACKAGE } = SuperAdminPaths;

  const [tableData, setTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<pages>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isRootAdmin, setIsRootAdmin] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  // Replace this with your own way of getting the user ID
  const userId = useSelector((state: any) => state.auth.userId);

  // Fetch the user's role and organization
  const { data: userData, loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: { userWithRolesByIdId: userId },
    onCompleted(data) {
      const roles = data?.userWithRolesById?.roles || [];
      const isAdmin = roles.some((role: any) => role.roleCode === "ROOT_ADMIN");
      setIsRootAdmin(isAdmin);
      const orgId = data?.userWithRolesById?.organization || null;
      setOrganizationId(orgId);
    },
  });

  const [searchCriteria, setSearchCriteria] = useState({
    name: '',
    totalTrainings: '',
    totalGroups: ''
  });
  const handleSearchChange = (field: string, value: string) => {
    setSearchCriteria(prev => ({
      ...prev,
      [field]: value
    }));
  };

  useEffect(() => {
    if (!userLoading && !isRootAdmin && organizationId) {
    }
  }, [userLoading, isRootAdmin, organizationId]);

  // Query for root admin
  const { loading: trainingLoading } = useQuery(GET_TRAINING_MODULE, {
    skip: !isRootAdmin,
    variables: {
      page: currentPage,
      limit: globals.pageSize,
      search: {
        name: searchCriteria.name,
        totalTrainings: searchCriteria.totalTrainings ? parseInt(searchCriteria.totalTrainings, 10) : null,
        totalGroups: searchCriteria.totalGroups ? parseInt(searchCriteria.totalGroups, 10) : null,
      }
    },
    onCompleted(data) {
      if (data.getAllTrainingPackages.trainingPackages.length > 0) {
        const trainingP = data?.getAllTrainingPackages?.trainingPackages;
        setTotalPages({
          currentPage: data.getAllTrainingPackages?.currentPage,
          limit: globals.pageSize,
          totalDocs: data.getAllTrainingPackages?.totalDocs,
          totalPages: data.getAllTrainingPackages?.totalPages,
        });
        setTableData([]);
        if (trainingP.length > 0) {
          const _trainingP = trainingP.map((_singleTP: any, index: number) => {
            return {
              key: index,
              id: _singleTP._id,
              trainingPackageName: _singleTP.name,
              totalTrainings: _singleTP.trainings.length,
            };
          });
          setTableData(_trainingP);
        }
      }
    },
  });
  const filteredData = tableData.filter(item => {
    const matchesName = item.trainingPackageName.toLowerCase().includes(searchCriteria.name.toLowerCase());

    const totalTrainingsMatch = 
      searchCriteria.totalTrainings === '' || 
      item.totalTrainings === parseInt(searchCriteria.totalTrainings, 10);

    const totalGroupsMatch = 
      searchCriteria.totalGroups === '' || 
      item.totalGroups === parseInt(searchCriteria.totalGroups, 10);

    return matchesName && totalTrainingsMatch && totalGroupsMatch;
  });
  // Query for non-root admin
  const { loading: orgLoading } = useQuery(GET_ORG_BY_ID, {
    skip: isRootAdmin || !organizationId,
    variables: { organizationByIdId: organizationId },
    onCompleted(data) {
      let trainingPackages = data?.organizationById?.trainingPackage || [];
      // Ensure trainingPackages is an array
      if (!Array.isArray(trainingPackages)) {
        trainingPackages = [trainingPackages];
      }
      setTotalPages({
        currentPage,
        limit: globals.pageSize,
        totalDocs: trainingPackages.length,
        totalPages: Math.ceil(trainingPackages.length / globals.pageSize),
      });
      const _trainingP = trainingPackages.map((_singleTP: any, index: number) => {
        return {
          key: index,
          id: _singleTP._id,
          trainingPackageName: _singleTP.name,
          totalTrainings: _singleTP.trainings?.length || 0,
        };
      });
      setTableData(_trainingP);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: (
        <TableHeading heading="Package Name" setSearch={(value) => handleSearchChange('name', value)} setPage={setCurrentPage} />
      ),
      dataIndex: "trainingPackageName",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "1",
      width: "30%",
    },
    {
      title: (
        <TableHeading heading="Total Trainings" setSearch={(value) => handleSearchChange('totalTrainings', value)} setPage={setCurrentPage} />
      ),
      dataIndex: "totalTrainings",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "2",
    },
    {
      title: (
        <TableHeading heading="Total Groups" setSearch={(value) => handleSearchChange('totalGroups', value)} setPage={setCurrentPage} />
      ),
      dataIndex: "trainingModule",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "3",
    },
    {
      title: '',
      render: (_, record) => (
        <Button variant="contained" sx={{ alignSelf: 'flex-end' }}>
          <Link style={{ textDecoration: 'none', color: 'white' }} to={record.id} state={{ trainingPackageId: record.id }}>
            View Details
          </Link>
        </Button>
      ),
      key: "4",
      width: "20%",
    },
  ];

  const loading = userLoading || (isRootAdmin ? trainingLoading : orgLoading);

  return (
    <>
      <Stats buttonText="Create Package" text="Total Training Package" number={filteredData.length} link={CREATE_TRAINING_PACKAGE} />
      <Card>
        <DataTable
          loading={loading}
          tableData={filteredData}
          totalPages={totalPages}
          showCheck={false}
          columnData={columns}
          page={(cp) => {
            setCurrentPage(Number(cp));
          }}
          scrollX="max-content"
          scrollY={450}
        />
      </Card>
    </>
  );
};

export default TrainingPackage;
