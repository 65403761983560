import { Form, Input, Radio } from "antd";
import { Rule } from "antd/es/form";

type formItemProp = {
  label: string;
  name?: string; // Changed type to string for better type safety
  rules?: Rule[];
  type: string;
  value?: string; // Ensure this is correctly passed and used
  onChange?: (e: any) => void; // Ensure onChange is defined
  readOnly?: boolean; // Added readOnly prop
};

const FormItemInput = ({ label, name, rules, type, value, onChange, readOnly }: formItemProp) => {
  return (
      <Form.Item label={label} name={name} rules={rules} initialValue={value}>
      {type === "radio" ? (
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={"male"}>Male</Radio>
          <Radio value={"female"}>Female</Radio>
        </Radio.Group>
      ) : (
        <Input
        type={type}
        value={readOnly ? value : undefined} // If read-only, set the value directly
        onChange={readOnly ? undefined : onChange} // Don't bind onChange if it's read-only
        readOnly={readOnly} // Ensure this prop is passed correctly
      />
      )}
    </Form.Item>
  );
};

export default FormItemInput;
