import { gql } from '@apollo/client';
import client from './apolloClient'; // Import client as default

const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file) {
      success
      message
      imageUrl
    }
  }
`;

export const uploadImage = async (file: File): Promise<{ imageUrl: string }> => {
  try {
    const { data } = await client.mutate({
      mutation: UPLOAD_IMAGE,
      variables: { file },
      context: {
        headers: {
          'Apollo-Require-Preflight': 'true',
        },
      },
    });

    if (data.uploadImage.success) {
      return { imageUrl: data.uploadImage.imageUrl };
    } else {
      throw new Error(data.uploadImage.message);
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};
